<template>
  <div id="app">
    <div id="nav">

      <b-navbar class="menu-bar" toggleable="lg" type="light" variant="primary">
        <div class="menu-items container">
          <b-navbar-brand href="/">
            <img src="./assets/newdesign/logo.svg" width="115" />
          </b-navbar-brand>

          <b-navbar-nav class="ml-auto mynavbar">
            <div class="d-flex justify-content-center align-items-center" v-if="this.$route.name == 'Home' ||this.$route.name == 'Cart'" >
              <img src="./assets/newdesign/kurv.png" class="basket" alt="kurv" width="25" @click="$router.push('/kurv')"/>
              <label class="total" @click="$router.push('/kurv')">{{ total }} DKK</label>
            </div>
          </b-navbar-nav>
        </div>
      </b-navbar>
      <div class="threedsecure">
        <a href="https://gls-group.eu/DK/da/falske-henvendelser" target="_blank">Phishing mails i omløb - Læs mere her</a>
      </div>
    </div>
    <router-view />
    <Footer />
  </div>
</template>
<style lang="scss" scoped>

#app {
  font-family: 'NewsonGLS-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.menu-bar{
  height:86px;
  @media screen and(max-width: 976px) {
     height:104px;
  }
}
.mynavbar{
  align-self: end;
}
.total-text{
  cursor: pointer;
}
.total {
  font-size: 18px;

  color:#FFF;
  cursor: pointer;
  font-family: 'NewsonGLS-Bold';
  margin-bottom: 0;
  margin-left: 10px;
}
.menu-items {
  display: flex;
  align-items: center;
}
.basket{
  cursor: pointer;
}
</style>
<script>
import Footer from "@/components/Footer";
import { mapState } from "vuex";

export default {
  components: { Footer },
  created() {
    this.$store.dispatch("parcels/loadCountries").then(() => {
      this.$store.dispatch("parcels/loadDawaZipcodes");
      this.$store.dispatch("parcels/getCart").then(()=>{
          this.cart.forEach(x => {
            if(x.id > 0)
              this.$store.commit('parcels/SET_COUNTER',1);
          })
      });
    });
  },
  methods: {},
  computed: {
    ...mapState("parcels", ["cart", "editing", "counter"]),
    total() {
      let sum = 0;
      this.cart.map(x => {
        sum += x.package.price;
      });
      return sum;
    },
    editingParcels(){
      return this.cart.length == this.counter
    }
  }
};
</script>
