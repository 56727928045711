const labelApi = {
  url:'https://sendapi.gls.dk/api'
  // url:'https://send-test-api.gls.dk/api'
  //url:'https://localhost:5011/api'
};
const glsAutoCompleteApi = {
  url:'https://glsdk-app-autocompleteapi-prod.azurewebsites.net'
  // url:'https://glsdk-app-autocompleteapi-test.azurewebsites.net'
  //url:'https://localhost:7115'
};
const packageInfo = {
  maxLength: 200,
  longestSide: 300
};
export { labelApi, glsAutoCompleteApi, packageInfo };
