import axios from "axios";
import { glsAutoCompleteApi } from "../config";

const apiClient = axios.create({
  baseURL: `https://dawa.aws.dk`,
  withCredentials: false,
  headers: { Accept: "application/json", "Content-Type": "application/json" }
});

const glsApiClient = axios.create({
  baseURL: glsAutoCompleteApi.url,
  withCredentials: false,
  headers: { Accept: "application/json", "Content-Type": "application/json", "Secret": "mA#Wu5JJunrFE^jaq9kzNArVpaDETk" }
});

export const DawaService = {
  async getAccessAddressById(id) {
    return await apiClient.get(`/adgangsadresser/${id}`);
  },
  async getAddressById(id) {
    return await apiClient.get(`/adresser/${id}`);
  },
  async addressByFuzzyQuery(query) {
    
    return await apiClient.get(
      `https://dawa.aws.dk/adresser?q=${query.betegnelse}&struktur=mini`
    );
  },
  async addressLookup(query, type) {
    //for direct dawa api request
    // const data = await apiClient.get(
    //   `/autocomplete?q=${query}&type=adresse&caretpos=5&supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=true&fuzzy=`
    // );
    //for gls api gateway request
    const data = await glsApiClient.get(`/dawaautocomplete?addressType=${type}&caretPosition=5&fuzzy=&query=${query}`);
    let streets = [];
    data.data.forEach(element => {
      if (element.type === "vejnavn") {
        streets.push(element)

        // streets.push({
        //   type: element.type,
        //   tekst: element.forslagstekst.replace("\n", ", "),
        //   betegnelse: element.tekst,
        //   streetname: "",
        //   houseNumber: "",
        //   floor: "",
        //   door: "",
        //   zipcode: "",
        //   city: ""
        // });
      } else if (element.type === "adgangsadresse") {
        streets.push(element)
        // streets.push({
        //   type: element.type,
        //   tekst: element.forslagstekst.replace("\n", ", "),
        //   betegnelse: element.tekst,
        //   streetname: "",
        //   houseNumber: "",
        //   floor: "",
        //   door: "",
        //   zipcode: "",
        //   city: ""
        // });
      } else if (element.type === "adresse") {
        streets.push(element)
        // streets.push({
        //   type: element.type,
        //   tekst: element.forslagstekst.replace("\n", " "),
        //   streetname: element.data.vejnavn,
        //   houseNumber: element.data.husnr,
        //   floor: element.data.etage,
        //   door: element.data.dør,
        //   zipcode: element.data.postnr,
        //   city: element.data.postnrnavn
        // });
      }
    });
    return streets;
  },

  async getAllZipcode() {
    return await apiClient.get(`/postnumre`);
  }
};
