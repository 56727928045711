<template>
  <div class="col-sm-12 col-md-6">


    <div class="row mt-3">

      <div class="col-12">

        <div class="font-weight-bold header-space">
          3. Vælg pakkens vægt
        </div>
        <div class="mt-1 pr-5 pl-5 row justify-space-between">

            <b-button class="col-md-5 col-lg-3 col-sm-5 space btn-outline-primary no-outline" :class="{'primary-text':parcel.package.weight == 1}" :variant="parcel.package.weight == 1 ? 'primary':'white'" @click="parcel.package.weight = 1">0-1 kg</b-button>
            <b-button class="col-md-5 col-lg-3 col-sm-5 space btn-outline-primary no-outline" :class="{'primary-text':parcel.package.weight == 5}" :variant="parcel.package.weight == 5 ? 'primary':'white'" @click="parcel.package.weight = 5">1-5 kg</b-button>
            <b-button class="col-md-5 col-lg-3 col-sm-5 space btn-outline-primary no-outline" :class="{'primary-text':parcel.package.weight == 10}" :variant="parcel.package.weight == 10 ? 'primary':'white'" @click="parcel.package.weight = 10">5-10 kg</b-button>
            <b-button class="col-md-5 col-lg-3 col-sm-5 space btn-outline-primary no-outline" :class="{'primary-text':parcel.package.weight == 15}" :variant="parcel.package.weight == 15 ? 'primary':'white'" @click="parcel.package.weight = 15">10-15 kg</b-button>
            <b-button class="col-md-5 col-lg-3 col-sm-5 space btn-outline-primary no-outline" :class="{'primary-text':parcel.package.weight == 20}" :variant="parcel.package.weight == 20 ? 'primary':'white'" @click="parcel.package.weight = 20">15-20 kg</b-button>
          

        </div>
         <p class="package-text-size">
          <!-- Minimum 10 x 15 x 1 cm<br/>
          Maks. 200 cm på længste side<br/>
          Maks. 300 cm i længe + omkreds<br/> -->
          <!-- Mindste pakkestørrelse 10 x 15 x 1 cm<br><br>
          <b>Maks. størrelse:</b><br>
          Maks. 200 cm på længste side<br>
          Længde + omkreds må ikke overskride 300 cm*<br>
          Maks. 20 kg<br><br>
          *Find pakkens størrelse ved at måle pakkens længste side. Hertil lægges pakkens omkreds. (Omkredsen måles som 2 x højde + 2 x bredde). -->
          Mindste pakkestørrelse 10 x 15 x 1 cm<br><br>
          <b>Maks. 200 cm på længste side*</b><br>
          Længde + omkreds må ikke overskride 300 cm**<br>
          Maks. 20 kg<br><br>
          *Pakker til Sverige maks 180 cm på længste side<br>
          **Find pakkens størrelse ved at måle pakkens længste side. Hertil lægges pakkens omkreds. (Omkredsen måles som 2 x højde + 2 x bredde).
        </p>
      </div>
    </div>

    <b-modal
      v-model="sizeModal"
      id="modal-center"
      centered
      title="Vigtig info"
      ok-only
    >
      {{ this.sizeModalTekst }}
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { checkMaximumSize, checkMinimumSize } from "@/utils/sizeCalculator";

export default {
  name: "Size",
  props: {
    parcelId: {
      type: Number,
      required: true
    },
    selectedCountry: {
      type: Number,
      required: true
    },
    sizeDecimalError: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      sizeModalTekst: "",
      sizeModal: false,
      height: 0,
      width: 0,
      length: 0,
      weight: 0,
      error: false,
      errorMessage: "",
      errorMessageWeight: ""
    };
  },
  computed: {
    ...mapState("parcels", ["cart"]),
    parcel() {
      return this.cart.filter(x => x.id === this.parcelId)[0];
    }
  },
  methods: {
    fokusOutWeight() {
      if(!this.sizeModal) {
        if (
          this.parcel.package.weight === "" ||
          this.parcel.package.weight === 0
        ) {
          this.parcel.package.weight = 0;
          this.sizeModalTekst = "Pakkens vægt må ikke være 0 kg.";
          this.sizeModal = true;
        }
        if (this.parcel.package.weight > 20) {
          this.parcel.package.weight = 20;
          this.sizeModalTekst =
            "Din pakke kan maks. veje 20 kg, når den bliver sendt fra en GLS PakkeShop.";
          this.sizeModal = true;
        }
      }
    },
    focusOutMeasurement(type) {
      if (type === "length") {
        const length = this.parcel.package.length;
        this.parcel.package.length =
          this.parcel.package.length === ""
            ? 0
            : this.parcel.package.length > 200
            ? 200
            : this.parcel.package.length;
        if(length > 200) {
          this.sizeModalTekst = "Maks længde er 200cm";
          this.sizeModal = true;
        }
      }
      if (type === "height") {
        const height = this.parcel.package.height;
        this.parcel.package.height =
          this.parcel.package.height === ""
            ? 0
            : this.parcel.package.height > 200
            ? 200
            : this.parcel.package.height;
        if(height > 200) {
          this.sizeModalTekst = "Maks højde er 200cm";
          this.sizeModal = true;
        }
      }
      if (type === "width") {
        const width = this.parcel.package.width;
        this.parcel.package.width =
          this.parcel.package.width === ""
            ? 0
            : this.parcel.package.width > 200
            ? 200
            : this.parcel.package.width;
        if(width > 200) {
          this.sizeModalTekst = "Maks bredde er 200cm";
          this.sizeModal = true;
        }
      }
    },
    updateSize() {
      this.error = false;
      if (
        !checkMaximumSize(
          this.parcel.package.height,
          this.parcel.package.width,
          this.parcel.package.length
        )
      ) {
        this.error = true;
        this.errorMessage =
          "Pakkens længde og omkreds må højst være 3 m. Den beregnes som 2 x højde + 2 x bredde + 1 x den længste side. Den længeste side må maks. være 2 m.";
        return;
      }
      if (
        (this.parcel.package.height > 0,
        this.parcel.package.width > 0,
        this.parcel.package.length > 0)
      ) {
        if (
          !checkMinimumSize(
            this.parcel.package.height,
            this.parcel.package.width,
            this.parcel.package.length
          )
        ) {
          this.error = true;
          this.errorMessage = "Min. størrelse for en pakke er 10x15x1 cm";
          return;
        }
      }
      if (
        this.parcel.package.height > 0 &&
        this.parcel.package.width > 0 &&
        this.parcel.package.length > 0 &&
        this.parcel.package.weight > 0 &&
        this.parcel.package.weight <= 20
      ) {
        this.$store.dispatch("parcels/updateParcelSize", {
          height: this.parcel.package.height,
          width: this.parcel.package.width,
          length: this.parcel.package.length,
          weight: this.parcel.package.weight,
          id: this.parcelId,
          countryId: this.selectedCountry
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.primary-text{
  color:#FFF;
}
.secondary-text{
  color:#061AB1;
}
.package-text-size{
  font-family: NewsonGLS-Regular;
  color:#000 !important;
  font-size:12px;
  margin-left:40px;
  margin-top:10px;
  
}
.space{
  margin-left:5px;
  margin-bottom:5px;
  box-sizing: border-box;
}
.size-label {
  width: 80px;
  @media screen and (max-width: 965px) {
  }
}

.size-range {
  width: 100px;
  @media screen and (max-width: 1000px) {
    width: 80px;
    display: none;
  }
}

.size-input {
  width: 60px;
}

.measure {
  width: 60px;
  margin-left: 5px;
  margin-top: 4px;
}
.header-space{
  margin-left: 40px;
  margin-bottom: 15px;
}

</style>
