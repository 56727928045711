<template>
    <div>
        <div class="autocomplete2-container">
            <input
                type="text"
                ref="address"
                class="form-control"
                autocomplete="new-password"
                @input="InputChanged"
                @blur="hideSelectList(); validateInput();"       
                :class="{'is-valid':hasValidAddress(), 'is-invalid':errorField}"
                @keydown="
                    e => {
                        keyDownHandler(e);
                    }
                "
            />    
        </div>
        <div v-show="selectListVisible" class="autocomplete2-container">
            <ul
                class="dawa-autocomplete-suggestions"
                style="margin: 1em, 0, 0, 0em; overflow;"
            >
                <li
                    v-for="(item, i) in items"
                    :key="i"
                    :class="
                        i === selectedItem
                        ? 'dawa-autocomplete-suggestion dawa-selected'
                        : 'dawa-autocomplete-suggestion'
                    "
                    @mousedown="selectAddress(item)"
                >
                    {{ item.tekst }}
                </li>
            </ul>
        </div>
    </div>
  </template>
  
  <script>
  import { DawaService } from "../../services/DawaService";
  export default {
    props: [
        "parcel", 
        "parcelIndex", 
        "errorField", 
        "type"
    ],
    mounted() {
        this.$emit("rendered");
        this.clearItems();
        this.resetAutoCompleteType();
        this.$refs.address.value = this.ShowChoosenAddress();
    },
    data: () => ({
        selectListVisible: false,
        items: [],
        selectedItem: 0,
        autoCompleteType: ""
    }),
    computed: {
        storeStreetName() {
            if(this.type == "afsender") {
                return this.parcel.afsender.afsendervejnavn;
            }
            else if(this.type == "modtager") {
                return this.parcel.modtager.modtagervejnavn;
            }
            else {
                return "";
            }
        }
    },
    watch: {
        storeStreetName() {
            this.$refs.address.value = this.ShowChoosenAddress();
        }
    },
    methods: {
        hasValidAddress() {
            if(this.type == "afsender") {
                if(this.parcel.afsender.afsendervejnavn  !== ""){
                    return true;
                }
            }
            else if(this.type == "modtager") {
                if(this.parcel.modtager.modtagervejnavn !== ""){
                    return true;
                }
            }
            return false;
        },
        ShowChoosenAddress() {            
            if (this.parcel) {
                if (
                    this.type == "afsender" &&
                    this.parcel.afsender.afsendervejnavn != ""
                ) {
                    return (
                        this.parcel.afsender.afsendervejnavn +
                        " " +
                        this.parcel.afsender.afsenderhusnr +
                        ", " +
                        this.parcel.afsender.afsenderpostnr +
                        " " +
                        this.parcel.afsender.afsendercity
                    );
                }
                if (
                    this.type == "modtager" &&
                    this.parcel.modtager.modtagervejnavn != ""
                ) {
                    return (
                        this.parcel.modtager.modtagervejnavn +
                        " " +
                        this.parcel.modtager.modtagerhusnr +
                        ", " +
                        this.parcel.modtager.modtagerpostnr +
                        " " +
                        this.parcel.modtager.modtagercity
                    );
                }
            }
            return "";
        },
        validateInput() {
            var input = this.$refs.address.value;
            if(input == "") {
                this.$emit('resetaddress', this.type);
            }
        },
        clearItems() {
            this.items = [];
            this.selectedItem = 0;                
        },
        resetAutoCompleteType()
        {
            this.autoCompleteType = this.selectAutoCompleteType();
        },
        hideSelectList()
        {
            this.selectListVisible = false;
        },
        showSelectList()
        {
            this.selectListVisible = true;
        },
        InputChanged(e) {
            var val = e.target.value;

            this.autoComplete(val, this.autoCompleteType);
            this.$emit("dawa", val);
        },
        autoComplete(val, autoCompleteType)
        {                
            if (!val) 
            {
                this.clearItems();
                this.resetAutoCompleteType();
                return;
            }
            
            if (this.isLoading) {
                this.rerunWhenFinished = true;
                return;
            }

            clearTimeout(this._timerId);

            // delay new call 200ms
            this._timerId = setTimeout(() => {
                this.isLoading = true;

                DawaService.addressLookup(val, autoCompleteType)
                    .then(
                        response => {
                            if (response) {                                    
                                this.items = response.slice(0, 20);
                                this.showSelectList();
                            }
                        },
                        error => {
                            console.error(error);
                        }                            
                    )
                    .finally(() => {
                        this.isLoading = false;
                        if (this.rerunWhenFinished) {
                            this.rerunWhenFinished = false;
                        }
                    });
            }, 200);
        },
        selectAddress(selected) {
            if (selected) {
                this.$refs.address.value = selected.tekst;
                this.autoCompleteType = this.selectAutoCompleteType(selected.type);

                if(selected.type == "adresse")
                {
                    this.$emit("selected", selected.data);                    
                } 
                else {
                    this.$refs.address.value = selected.tekst;
                    this.autoComplete(this.$refs.address.value, this.autoCompleteType);                
                }                    
            }
            this.clearItems();
        },
        selectAutoCompleteType(base){
            switch(base){
                case "adresse":
                case "adgangsadresse": return "adresse";
                case "vejnavn": return "adresse";
                default: return "vejnavn";                    
            }
        },
        keyDownHandler(e) {                  
            if (e && !this.selectListVisible) {
                const key = window.event ? e.keyCode : e.which;
                // down (40)
                if (key === 40) {      
                    this.showSelectList();
                }
            }
            else if (e && this.items && this.items.length > 0) {
                const key = window.event ? e.keyCode : e.which;
                // down (40)
                if (key === 40) {      
                    this.selectedItem = (this.selectedItem + 1) % this.items.length;
                }
                //up (38)
                else if (key === 38) {
                    this.selectedItem = (this.selectedItem - 1 + this.items.length) % this.items.length;
                }
                // enter or tab
                else if (key === 13 || key === 9) {
                    this.selectAddress(this.items[this.selectedItem]);
                    e.preventDefault();
                }
                // esc
                else if (key === 27) {
                    this.hideSelectList();
                }
                // backspace
                else if (key === 8) {                        
                    this.clearItems();
                    this.resetAutoCompleteType();
                }
            }
        } 
    }
  };
  </script>
  
  <style lang="scss">
  .errorStyle {
    border-color: red;
  }
  .autocomplete2-container {
    /* relative position for at de absolut positionerede forslag får korrekt placering.*/
    position: relative;
    width: 100%;
    max-width: 40em;
  }
  .autocomplete2-container input {
    /* Både input og forslag får samme bredde som omkringliggende DIV */
    width: 100%;
    box-sizing: border-box;
  }
  .dawa-autocomplete-suggestions {
    margin: 0.3em 0 0 0;
    padding: 0;
    text-align: left;
    border-radius: 0.3125em;
    background: #fcfcfc;
    box-shadow: 0 0.0625em 0.15625em rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion {
    margin: 0;
    list-style: none;
    cursor: pointer;
    padding: 0.4em 0.6em;
    color: #333;
    border: 0.0625em solid #ddd;
    border-bottom-width: 0;
  }
  .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-bottom-width: 0.0625em;
  }
  .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected,
  .dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover {
    background: #f0f0f0;
  }
  </style>
  