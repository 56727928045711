<template>
  <div class="home">
    <section class="hero container d-flex flex-column align-items-center">
      <img src="../assets/newdesign/main.png" class="main-image" />
      <b-button variant="link" v-b-modal.modal-price class="customB"
        >Se Priser her</b-button
      >
      <p style="font-size: 20px; text-align: center;">
        Tjek vores <a href="https://gls-group.eu/DK/da/privat/send/guide-sikker-indpakning" target="_blank" class="packing-link">Guide til sikker indpakning</a>
      </p>
      <p style="font-size: 20px; text-align: center;">
        Send dine pakker med dag-til-dag levering fra mere end 1.700 PakkeShops.
      </p>
    </section>
    <section class="packages container mt-2">
      <div class="" role="tablist">
        <PackageLine
          :parcelId="parcel.id"
          :row="index + 1"
          v-for="(parcel, index) in cart"
          :key="parcel.id"
          v-on:edit="edit"
          v-on:done="done"
        />
      </div>
    </section>
    <section
      class="d-flex flex-column container front-buttons"
      v-if="editmode.length === 0 && counter > 0"
    >
      <b-button
        class="ml-auto mt-5 action front-button rounded-pill btn-outline-primary"
        variant="white"
        @click="addNewPackage"
        >Tilføj flere pakker</b-button
      >
      <b-button
        class="ml-auto mt-1 action front-button rounded-pill"
        variant="primary"
        @click="$router.push('/kurv')"
        >Gå til kurven</b-button
      >
    </section>
    <b-modal size="lg" centered id="modal-price" ok-only>
      <img
        src="../assets/pakkepriser-send-gls.png"
        alt="Pakkkepriser"
        title="Pakke priser"
        style="width:100%;"
      />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src

import PackageLine from '@/components/PackageLine';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    PackageLine,
  },
  data() {
    return {
      editmode: [],
    };
  },
  computed: {
    ...mapState('parcels', ['cart', 'counter']),
  },
  created() {
    //this.$gtag.pageview(this.$route);
    if (this.cart.filter((x) => x.id === 0).length > 0)
      this.editmode.push({ id: 0 });
  },
  methods: {
    addNewPackage() {
      this.$store.dispatch('parcels/addProduct');
      this.editmode.push({ id: 0 });
    },
    edit(evt) {
      if (this.editmode.find((x) => x.id === 0))
        this.editmode = this.editmode.filter((x) => x.id !== 0);
      this.editmode.push(evt);
    },
    done(evt) {
      this.editmode = this.editmode.filter((x) => x.id !== evt.id);
    },
  },
};
</script>
<style lang="scss">
h1 {
  font-weight: 700;
}
.home {
  min-height: 811px;
}
.packing-link:hover{
  color:#172154 !important;
}
.package-text {
  font-color: rgb(23, 33, 84);
  font-family: TheSansGLS;
}
.price-table,
th,
td {
  border: 1px solid black;
}
.price-table {
  width: 100%;
  margin-bottom: 20px;
  font-family: TheSansGLS;
  border-collapse: collapse;
  tr {
    th {
      background: rgb(23, 33, 84);
      color: #fff;
      font-size: 14px;
    }
    th.weight {
      font-size: 10px;
      text-align: center;
    }
    th.title {
      width: 200px;
      padding-right: 50px;
    }
    td {
      font-size: 12px;
    }
    td.weight {
      text-align: center;
    }
  }
}
.main-image {
  width: 100%;
}
.mobile {
  display: none;
  @media screen and(max-width: 758px) {
    display: block;
    margin-bottom: 10px;
    margin-top: 40px;
    width: 100%;
  }
}
.desktop {
  margin-bottom: 10px;
  margin-top: 40px;
  width: 90%;
  @media screen and(max-width: 758px) {
    display: none;
  }
}
</style>
