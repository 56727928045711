<template>
  <!-- TrustBox widget - Mini -->
  <div class="trustpilot-widget" data-locale="da-DK" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5bf67a9bdb9f340001e8ea73" data-style-height="150px" data-style-width="100%" data-theme="dark">
    <a href="https://dk.trustpilot.com/review/gls-group.eu/dk/da/home" target="_blank" rel="noopener">Trustpilot</a>
  </div>
  <!-- End TrustBox widget -->
</template>

<script>
    export default {
        
    }
</script>
