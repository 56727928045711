
import { packageInfo } from "@/config";

const getSize = (weight) => {
  //const values = [Math.abs(+height), Math.abs(+width), Math.abs(+length)];
  //return max(values) + min(values);
  switch(weight){
    case 1:
      return 34;
    case 5:
      return 49;
    case 10:
      return 64;
    case 15:
      return 79;
    default:
      return 299;
  }
};
const checkMaxLength = (height, width, length) => {
  if (
    Math.abs(+height) > packageInfo.maxLength ||
    Math.abs(+width) > packageInfo.maxLength ||
    Math.abs(+length) > packageInfo.maxLength
  ) {
    return false;
  }
  return true;
};
const checkMaximumSize = (height, width, length) => {
  const widthSide = Math.abs(+width) * 2;
  const heightSide = Math.abs(+height) * 2;
  if ((widthSide + heightSide + Math.abs(+length)) > packageInfo.longestSide) {
    return false;
  }
  return true;
};
const checkMinimumSize = (height, width, length) => {
  let values = [Math.abs(+height), Math.abs(+width), Math.abs(+length)];
  values = values.sort((a,b)=> a-b).reverse();
  if(values[0] < 15 || values[1] < 10){
    return false;
  }

  return true;
};

const checkMaxWeight = (weight) => {
  if(weight > 20 || weight < 0){
    return true;
  }
  return false;
};

export { getSize, checkMaxLength, checkMaximumSize,checkMinimumSize, checkMaxWeight };
