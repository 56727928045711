<template>
  <div class="package-line mb-1">
    <b-button
      id="togglebut"
      class="col-12 rounded-0 mb-1 line"
      variant="white"
      ref="togglebut"
    >
      <div class="row line-border">
        <div class="col-8 p-2 text-left">
          <span class="ml-1">
            <span class="parcel-text">Pakke {{ row }}</span>
            <!--<span class="ml-1">
              {{
                parcel.modtager.modtagernavn.length > 0
                  ? `Modtager:  ${parcel.modtager.modtagernavn}`
                  : ""
              }}{{
                company && parcel.modtager.modtagernavn.length === 0
                  ? `Modtager: ${parcel.modtager.modtagerfirma}`
                  : ""
              }}</span
            >-->
          </span>
        </div>
        <div class="col-4">
          <div class="float-right icons" v-if="cart[0].id !== 0">
            <img
              src="../assets/newdesign/pencil.png"
              width="25"
              alt="rediger"
              style="margin-top:5px;"
              @click="toggleContent"
            />
            <img
              src="../assets/newdesign/trash.png"
              width="25"
              alt="slet"
              style="margin-top:5px;"
              @click="trash"
            />
          </div>
        </div>
      </div>
    </b-button>
    <b-collapse class="" :id="`collapse-${parcel.id}`">
      <b-card class="rounded-0 mb-1">
        <div class="row">
          <div class="col-sm-12 col-md-6">

            <b-card class="border-0">
              <div class="col-6">
                <div class="font-weight-bold">1. Afsender</div>
              </div>
              <div v-if="moreThanOneParcel && row > 1">
                <div class="col-12 mb-1">
                  <b-form-checkbox @change="copySenderAddress">
                    Kopier forrige afsenderinfo
                  </b-form-checkbox>
                </div>
              </div>
              <div>
                <div class="col-6">
                  <label class="labelsName">Land</label>
                  <b-form-input value="Danmark" disabled></b-form-input>
                </div>
                <div class="col-6">
                  &nbsp;
                </div>
              </div>
              <div>
                <div class="col-12">
                  <label class="labelsName">Navn</label>
                  <b-form-input
                    v-model.trim="$v.parcel.afsender.afsendernavn.$model"
                    :state="errorObj.afsender.afsendernavn"
                    @blur.native="validateState('afsender', 'afsendernavn')"
                    aria-describedby="name-required"
                  ></b-form-input>
                  <b-form-invalid-feedback id="name-required">
                    Navn skal udfyldes og må maks indeholde 40 tegn.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="mt-1">
                <div class="col-12">
                  <label class="labelsName">Adresse</label>
                  <!-- <DawaAutoComplete
                    value=""
                    autocomplete="off"
                    :items="senderAddresses"
                    :filterby="'tekst'"
                    @selected="addressSelected($event, 'afsender')"
                    :query-text="querySender"
                    :parcel="parcel"
                    type="afsender"
                    :error-field="dawa1"
                    v-on:resetaddress="resetaddress('afsender')"
                  /> -->
                  <AddressAutoComplete
                    value=""
                    autocomplete="off"
                    :items="senderAddresses"
                    :filterby="'tekst'"
                    @selected="addressSelected($event, 'afsender')"
                    :query-text="querySender"
                    :parcel="parcel"
                    type="afsender"
                    :error-field="dawa1"
                    v-on:resetaddress="resetaddress('afsender')"
                  />
                </div>
              </div>
              <div>
                <div class="row pl-3 pr-3">
                  <div class="col-sm-12 col-lg-5">
                    <label class="labelsName">Mobilnr.</label>
                    <b-form-input
                      v-model.trim="$v.parcel.afsender.afsenderphonenr.$model"
                      :state="errorObj.afsender.afsenderphonenr"
                      @blur.native="
                        validateState('afsender', 'afsenderphonenr')
                      "
                      aria-describedby="phone-required"
                    ></b-form-input>
                    <b-form-invalid-feedback id="phone-required">
                      Mobilnr. skal bestå af 8 tal
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-sm-12 col-lg-7">
                    <label class="labelsName">Mail</label>
                    <b-form-input
                      v-model.trim="$v.parcel.afsender.afsendermail.$model"
                      :state="errorObj.afsender.afsendermail"
                      @blur.native="validateState('afsender', 'afsendermail')"
                      aria-describedby="email-required"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-required">
                      Email er ikke korrekt eks. test@test.dk og maks 100 tegn
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
          <div class="col-sm-12 col-md-6">

            <b-card class="border-0">
              <div class="col-6 font-weight-bold">2. Modtager</div>
              <div class="row pl-3">
                <div v-if="moreThanOneParcel && row > 1 && parcel.modtager.modtagercountry != -1">
                  <div class="col-12 mb-1">
                    <b-form-checkbox @change="copyReceiverAddress">
                      Kopier forrige modtagerinfo
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-6" v-if="parcel.modtager.modtagercountry != -1">
                  <label class="labelsName">Land:</label>
                  <b-form-select
                    :options="countriesFiltered"
                    value-field="numericCode"
                    text-field="name"
                    v-model="parcel.modtager.modtagercountry"
                    @change="resetaddress('modtager')"

                  ></b-form-select>
                </div>
                <div class="col-6">
                  <template v-if="parcel.modtager.modtagercountry == 208">
                    <div>
                      <label class="labelsName"
                        >Levering til erhvervsadresse?</label
                      >
                      <small></small>
                      <b-form-checkbox
                        style="margin-top:-3px;"
                        v-model="company"
                        @change="clearPackageType"
                      >
                        Ja
                      </b-form-checkbox>
                    </div>
                  </template>
                </div>
              </div>
              <transition name="slide-fade" v-if="company">
                <div v-if="company || parcel.modtager.modtagercountry != 208">
                  <div class="col-12">
                    <label class="labelsName">Firma</label>
                    <b-form-input
                      v-model.trim="parcel.modtager.modtagerfirma"
                      :state="parcel.modtager.modtagerfirma > 0 ? true : null"
                      aria-describedby="email-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-modtager-required">
                      Firmanavn skal udfyldes og må maks indeholde 40 tegn.
                    </b-form-invalid-feedback>
                    <div
                      class="invalid-feedback"
                      style="display: block"
                      v-if="firmaLength"
                    >
                      Firmanavn skal udfyldes og må maks indeholde 40 tegn.
                    </div>
                  </div>
                </div>
              </transition>
              <div>
                <div class="col-12">
                  <label class="labelsName">Navn</label>
                  <b-form-input
                    v-model.trim="$v.parcel.modtager.modtagernavn.$model"
                    :state="errorObj.modtager.modtagernavn"
                    @blur.native="validateState('modtager', 'modtagernavn')"
                    aria-describedby="name-modtager-required"
                    :disabled="parcel.modtager.modtagercountry == -1"
                  ></b-form-input>
                  <b-form-invalid-feedback id="name-modtager-required">
                    Navn skal udfyldes og må maks indeholde 40 tegn.
                  </b-form-invalid-feedback>
                  <div
                    class="invalid-feedback"
                    style="display: block"
                    v-if="fullnameError"
                  >
                    Ved forsendelse til PakkeShop skal efternavn udfyldes
                  </div>
                </div>
              </div>
              <div class="mt-1" v-if="parcel.modtager.modtagercountry == 208">
                <div class="col-12">
                  <label class="labelsName">Adresse</label>
                  <!-- <DawaAutoComplete
                    value=""
                    autocomplete="off"
                    :items="senderAddresses"
                    :filterby="'tekst'"
                    @selected="addressSelected($event, 'modtager')"
                    :query-text="querySender"
                    type="modtager"
                    :parcel="parcel"
                    :error-field="dawa2"
                    v-on:resetaddress="resetaddress('modtager')"
                  /> -->
                  <AddressAutoComplete
                    value=""
                    autocomplete="off"
                    :items="senderAddresses"
                    :filterby="'tekst'"
                    @selected="addressSelected($event, 'modtager')"
                    :query-text="querySender"
                    type="modtager"
                    :parcel="parcel"
                    :error-field="dawa2"
                    v-on:resetaddress="resetaddress('modtager')"
                  />
                </div>
              </div>
              <div v-else>
                <div class="row pl-3 pr-3">
                  <div class="col-9">
                    <label class="labelsName">Vejnavn</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagervejnavn.$model"
                      :state="errorObj.modtager.modtagervejnavn"
                      @blur.native="
                        validateState('modtager', 'modtagervejnavn')
                      "
                      aria-describedby="street-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="street-modtager-required">
                      Vejnavn skal udfyldes og må være maks 50 tegn
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-3 pl-0">
                    <label class="labelsName">Nr.</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagerhusnr.$model"
                      :state="errorObj.modtager.modtagerhusnr"
                      @blur.native="validateState('modtager', 'modtagerhusnr')"
                      aria-describedby="house-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="house-modtager-required">
                      Husnr. skal udfyldes og må være maks 50 tegn
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="row pl-3 pr-3">
                  <div class="col-sm-12 col-lg-4">
                    <label class="labelsName">Post nr.</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagerpostnr.$model"
                      :state="errorObj.modtager.modtagerpostnr"
                      @blur.native="validateState('modtager', 'modtagerpostnr')"
                      aria-describedby="zipcode-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="zipcode-modtager-required">
                      {{ zipmessage ? zipmessage : "Postnr. skal udfyldes" }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-sm-12 col-lg-8">
                    <label class="labelsName">By</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagercity.$model"
                      :state="errorObj.modtager.modtagercity"
                      @blur.native="validateState('modtager', 'modtagercity')"
                      aria-describedby="city-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="city-modtager-required">
                      By skal udfyldes og må være maks 30 tegn
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
              <div>
                <div class="row pl-3 pr-3">
                  <div class="col-sm-12 col-lg-5">
                    <label class="labelsName">Mobilnr.</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagerphonenr.$model"
                      :state="errorObj.modtager.modtagerphonenr"
                      @blur.native="
                        validateState('modtager', 'modtagerphonenr')
                      "
                      aria-describedby="mobil-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="mobil-modtager-required">
                      {{
                        parcel.modtager.modtagercountry == "208"
                          ? "Mobilnr. skal bestå af 8 tal"
                          : "Mobilnr. skal udfyldes og maks 30 tegn"
                      }}
                    </b-form-invalid-feedback>
                  </div>

                  <div class="col-sm-12 col-lg-7 ">
                    <label class="labelsName">Mail</label>
                    <b-form-input
                      v-model.trim="$v.parcel.modtager.modtagermail.$model"
                      :state="errorObj.modtager.modtagermail"
                      @blur.native="validateState('modtager', 'modtagermail')"
                      aria-describedby="mail-modtager-required"
                      :disabled="parcel.modtager.modtagercountry == -1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="mail-modtager-required">
                      Mail skal udfyldes og må maks være 100 tegn
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row mt-4">
          <Size
            :parcelId="parcel.id"
            :selected-country="selectedCountry"
            :sizeDecimalError="sizeErrorDecimalNumberCheck"
          />
          <PackageType
            :parcelId="parcel.id"
            :selected-country="selectedCountry"
            :company="company"
            v-if="parcelSizeFilled && parcelRecieverAddressFilled"
            :depositError="depositError"
            v-on:confirm="confirm"
          />
        </div>
      </b-card>
    </b-collapse>
    <b-modal
      no-close-on-backdrop
      v-model="trashModal"
      id="modal-center"
      centered
      title="Fjern pakke ?"
      v-on:ok="deleteParcel"
      cancel-title="Annuller"
      footer-border-variant="0 none"
      header-border-variant="0 none"
      class="mymodal"
    >
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import DawaAutoComplete from "@/components/DawaAutoComplete";
import AddressAutoComplete from "@/components/inputs/AddressAutoComplete.vue"
import Size from "@/components/Size";
import PackageType from "@/components/PackageType";
import {
  required,
  minLength,
  maxLength,
  email,
  numeric, requiredIf
} from "vuelidate/lib/validators";
import { LabelOnlineService } from "@/services/LabelOnlineService";
import { checkMinimumSize } from "@/utils/sizeCalculator";
const phoneLength = (value,vm) => {

  if(vm.modtagercountry == '208') {
    return vm.modtagerphonenr.length === 8
  }
  else{
    return vm.modtagerphonenr.length > 0 && vm.modtagerphonenr.length <= 30
  }
}
export default {
  name: "PackageLine",
  components: { 
    PackageType, 
    Size, 
    // DawaAutoComplete 
    AddressAutoComplete
  },
  props: {
    parcelId: {
      type: Number,
      required: true
    },
    row: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      firmaLength:false,
      sizeErrorDecimalNumberCheck:false,
      depositError:false,
      fullnameError:false,
      checkMinimumSize,
      fillCompany: false,
      dirtyCompany: false,
      open: false,
      company: false,
      name: "",
      selectedCountry: 208,
      height: 0,
      width: 0,
      length: 0,
      senderAddresses: [],
      querySender: "",
      zipmessage: "",
      trashModal: false,
      dawa1: false,
      dawa2: false,
      phoneDenmark:null,
      phoneEU:null,
      errorObj: {
        afsender: {
          afsendernavn: null,
          afsenderphonenr: null,
          afsendermail: null,
          afsendervejnavn: null,
          afsenderpostnr: null,
          afsenderhusnr: null,
          afsendercity: null
        },
        modtager: {
          modtagernavn: null,
          modtagerphonenr: null,
          modtagermail: null,
          modtagervejnavn: null,
          modtagerhusnr: null,
        },
        package: {
          height: 1,
          length: 1,
          width: 1,
          weight: null,
          type: null,
          ps: null
        }
      },
      options: []
    };
  },
  computed: {
    ...mapState("parcels", ["countries", "cart", "editing", "counter"]),
    countriesFiltered(){
      return this.countries.filter(x=> x.numericCode != "-1")
    },
    parcelSizeFilled() {
      return (
        this.parcel.package.weight > 0
      );
    },
    parcelRecieverAddressFilled() {
      return (
        this.parcel.modtager.modtagervejnavn &&
        this.parcel.modtager.modtagervejnavn.length > 0
      );
    },
    minimumRequirement() {
      const succes = checkMinimumSize(
        this.parcel.package.height,
        this.parcel.package.width,
        this.parcel.package.length
      );

      return succes;
    },
    parcel() {
      return this.cart.filter(x => x.id === this.parcelId)[0];
    },
    companyCheck() {
      let required = null;
      if (this.company) {
        required = true;
      }
      if (this.parcel.serviceChoosen === 3) {
        required = true;
      }
      if (this.parcel.modtager.modtagerfirma.length > 0) {
        required = false;
      }

      return required;
    },
    moreThanOneParcel() {
      return this.cart.length > 1;
    }
  },
  validations: {
    parcel: {
      afsender: {
        afsendercity: {
          required,
          maxLength:maxLength(30)
        },
        afsenderhusnr: {
          required,
          maxLength:maxLength(50)
        },
        afsendermail: {
          required,
          email,
          maxLength:maxLength(100)
        },
        afsendernavn: {
          required,
          maxLength:maxLength(40)

        },
        afsenderphonenr: {
          required,
          maxLength: maxLength(8),
          minLength: minLength(8),
          numeric
        },
        afsenderpostnr: {
          required,
          maxLength: minLength(4),
          minLength: minLength(4),
          numeric
        },
        afsendervejnavn: {
          required
        }
      },
      modtager: {
        modtagernavn: {
          required: requiredIf(function(){
            return !this.company;
          }),
          maxLength:maxLength(40),
        },
        modtagervejnavn: {
          required,
          maxLength:maxLength(50)
        },
        modtagerhusnr: {
          required,
          maxLength:maxLength(50)
        },
        modtagerpostnr: {
          required,
          maxLength:maxLength(12)
        },
        modtagercity: {
          required,
          maxLength:maxLength(30)
        },
        modtagerphonenr: {
          required: requiredIf(function(){
            
            if(this.company){
              return false;
            }
            return true;
          }),
          phoneLength,
          numeric
          //maxLength: 2>3? maxLength(8) test
        },
        modtagermail: {
          required: requiredIf(function(){
            if(this.parcel.modtager.modtagercountry != "208")
              return false;
            else if(this.company){
              return false;
            }
            return true;
          }),
          email,
          maxLength:maxLength(100)
        },
        modtagerfirma: {
          required: requiredIf(function(){
            if(this.parcel.modtager.modtagercountry != "208")
              return true;
            else if(this.company){
              return true;
            }
            return false;
          }),
          maxLength:maxLength(50)
        }
      }
    }
  },
  created() {
    this.company = this.parcel.modtager.modtagerfirma.length > 0;
  },
  mounted() {
    if (this.parcelId == 0) {
      if (!this.open) {
        const but = this.$refs.togglebut;
        but.classList.value = but.classList.value + " expanded";

        this.$root.$emit("bv::toggle::collapse", "collapse-" + this.parcel.id);
        this.open = true;
      }
    }
    if (+this.$route.query.parcel === this.parcel.id) {
      if (!this.open && !this.editing) {
        const but = this.$refs.togglebut;
        but.classList.value = but.classList.value + " expanded";

        this.$root.$emit("bv::toggle::collapse", "collapse-" + this.parcel.id);
        this.open = true;
        this.$emit("edit", {
          id: this.parcel.id
        });
        this.$store.commit("parcels/SET_EDITING", true);
      }
    }
  },
  methods: {
    copySenderAddress(evt) {
      if (evt) {
        var parcelToCopyfrom = this.cart[this.row - 2].afsender;
        this.parcel.afsender = parcelToCopyfrom;
        this.$store.dispatch("parcels/copyAddress", {
          index: this.row - 1,
          type: "afsender",
          address: parcelToCopyfrom
        });
      } else {
        this.$store.dispatch("parcels/copyAddress", {
          index: this.row - 1,
          type: "afsender",
          address: {
            afsendernavn: "",
            afsendervejnavn: "",
            afsenderhusnr: "",
            afsenderpostnr: "",
            afsendercity: "",
            afsenderphonenr: "",
            afsendermail: "",
            afsendercountry: "208"
          }
        });
      }
    },
    copyReceiverAddress(evt) {
      if (evt) {
        var parcelToCopyfrom = this.cart[this.row - 2].modtager;

        this.$store.dispatch("parcels/copyAddress", {
          index: this.row - 1,
          type: "modtager",
          address: parcelToCopyfrom
        });
      } else {
        this.$store.dispatch("parcels/copyAddress", {
          index: this.row - 1,
          type: "modtager",
          address: {
            modtagernavn: "",
            modtagervejnavn: "",
            modtagerhusnr: "",
            modtagerpostnr: "",
            modtagercity: "",
            modtagerphonenr: "",
            modtagermail: "",
            modtagercountry: "208",
            modtagerfirma: ""
          }
        });
      }
    },
    deleteParcel() {
      this.$emit("done", { id: this.parcel.id });
      this.$store.commit("parcels/SET_EDITING", false);
      if (this.parcel.id > 0) this.$store.commit("parcels/SET_COUNTER", -1);
      this.$store.dispatch("parcels/deleteParcel", {
        id: this.parcelId,
        row: this.row
      });
      if (this.counter == 0) {
        this.$store.dispatch("parcels/addProduct");
      }
    },
    trash() {
      if (this.counter > 0) this.trashModal = true;
    },    
    validatePhoneState(name){
      this.$nextTick(function() {
        // do something cool
        const { $dirty, $error } = this.$v.[name];
        const check = $dirty ? !$error : null;

        this.errorObj['modtager'][name] = check;
        //update store with input
        this.$store.dispatch("parcels/updateParcel", this.parcel);
        return check;
      });
    },
    validateState(type, name) {
      if (name === "modtagerpostnr" && this.parcel.modtager.modtagerpostnr) {
        LabelOnlineService.checkZipcode(
          this.parcel.modtager.modtagerpostnr,
          this.parcel.modtager.modtagercountry
        ).then(({ data }) => {
          this.errorObj[type][name] = data.success;
          this.$store.dispatch("parcels/updateParcel", this.parcel);
          this.zipmessage = data.message;
          if (this.zipmessage === "OK") this.zipmessage = null;
          return data.success;
        });
      }
      this.$nextTick(function() {
        // do something cool
        const { $dirty, $error } = this.$v.parcel[type][name];
        const check = $dirty ? !$error : null;

        this.errorObj[type][name] = check;
        //update store with input
        this.$store.dispatch("parcels/updateParcel", this.parcel);
        return check;
      });
    },
    // updateSize(newPackage) {
    //   this.$store.dispatch("parcels/updateParcel", newPackage);
    // },
    selectedtype(newPackage) {
      this.parcel.package.serviceChoosen = newPackage;
      this.parcel.package.packageType = newPackage;

      this.$store.dispatch("parcels/updateParcel", this.parcel);
    },
    selectedps(newPackage) {
      this.parcel.package.parcelShopId = newPackage;
    },
    async clearPackageType() {
      if(!this.company) {
        this.$store.commit("parcels/SET_PARCEL_RESET_PRODUCT", {
          id: this.parcel.id
        });
      }
    },
    async addressSelected(evt, type) {      
      if (type === "afsender") {
        this.$v.parcel.afsender.afsendervejnavn.$touch();
        this.$v.parcel.afsender.afsenderhusnr.$touch();
        this.$v.parcel.afsender.afsendercity.$touch();
        this.$v.parcel.afsender.afsenderpostnr.$touch();
        this.validateState("afsender", "afsendervejnavn");
        this.validateState("afsender", "afsenderhusnr");
        this.validateState("afsender", "afsendercity");
        this.validateState("afsender", "afsenderpostnr");
        this.parcel.afsender.afsendervejnavn = evt.vejnavn;
        this.parcel.afsender.afsenderhusnr = `${evt.husnr}${
          evt.etage && evt.etage.length > 0 ? ", " + evt.etage : ""
        } ${evt.dør && evt.dør.length > 0 ? evt.dør : ""}`; //13, 1 th
        this.parcel.afsender.afsendercity = evt.postnrnavn;
        this.parcel.afsender.afsenderpostnr = evt.postnr;
        this.$store.dispatch("parcels/updateParcel", this.parcel);
      } else if (type === "modtager") {
        this.$v.parcel.modtager.modtagervejnavn.$touch();
        this.$v.parcel.modtager.modtagerhusnr.$touch();
        this.$v.parcel.modtager.modtagercity.$touch();
        this.$v.parcel.modtager.modtagerpostnr.$touch();
        this.validateState("modtager", "modtagervejnavn");
        this.validateState("modtager", "modtagerhusnr");
        this.validateState("modtager", "modtagercity");
        this.validateState("modtager", "modtagerpostnr");
        this.parcel.modtager.modtagervejnavn = evt.vejnavn;
        this.parcel.modtager.modtagerhusnr = `${evt.husnr}${
          evt.etage && evt.etage.length > 0 ? ", " + evt.etage : ""
        } ${evt.dør && evt.dør.length > 0 ? evt.dør : ""}`; //13, 1 th
        this.parcel.modtager.modtagercity = evt.postnrnavn;
        this.parcel.modtager.modtagerpostnr = evt.postnr;
        this.$store.dispatch("parcels/updateParcel", this.parcel);
      }
      this.$forceUpdate();
    },
    resetaddress(type) {
      if (type === "afsender") {
        this.dawa1 = false;
        this.parcel.afsender.afsendervejnavn = "";
        this.parcel.afsender.afsenderhusnr = "";
        this.parcel.afsender.afsendercity = "";
        this.parcel.afsender.afsenderpostnr = "";
        this.errorObj.afsender.afsendervejnavn = null;
        this.errorObj.afsender.afsenderhusnr = null;
        this.errorObj.afsender.afsendercity = null;
        this.errorObj.afsender.afsenderpostnr = null;
      } else if (type === "modtager") {
        this.dawa2 = false;
        this.parcel.modtager.modtagervejnavn = "";
        this.parcel.modtager.modtagerhusnr = "";
        this.parcel.modtager.modtagercity = "";
        this.parcel.modtager.modtagerpostnr = "";
        this.errorObj.modtager.modtagervejnavn = null;
        this.errorObj.modtager.modtagerhusnr = null;
        this.errorObj.modtager.modtagercity = null;
        this.errorObj.modtager.modtagerpostnr = null;
        this.parcel.package = {
          height: this.parcel.package.height,
          width: this.parcel.package.width,
          length: this.parcel.package.length,
          weight: this.parcel.package.weight,
          serviceChoosen: 0,
          parcelShopId: "",
          parcelShopName: "",
          packageType: 0,
          price: 0,
          productId: "",
          description: "",
          deposit: false,
          depositIntruction: null
        };
      }
      this.$store.dispatch("parcels/updateParcel", this.parcel);
    },
    toggleContent() {
      if (!this.open && !this.editing) {
        const but = this.$refs.togglebut;
        but.classList.value = but.classList.value + " expanded";

        this.$root.$emit("bv::toggle::collapse", "collapse-" + this.parcel.id);
        this.open = true;
        this.$emit("edit", {
          id: this.parcel.id
        });
        this.$store.commit("parcels/SET_EDITING", true);
      }
    },
    async checkSender() {
      let errors = false;
      for (const property in this.errorObj.afsender) {
        if (this.$v.parcel.afsender[property].$error) {
          if (this.company) {
            if (
              property === "modtagernavn" ||
              property === "modtagerphone" ||
              property === "modtagermail"
            ) {
              return false;
            }
          }
          errors = true;
          break;
        }
      }
      return errors;
    },
    async checkReciever() {
      let errors = false;
      for (const property in this.errorObj.modtager) {
        
        if(property === 'modtagerphonenr' && this.company){
          
          continue
        }
        if (this.$v.parcel.modtager[property].$error) {
          errors = true;
          break;
        }
      }
      return errors;
    },
    async confirm() {
      if(this.parcel.modtager.modtagerfirma .length > 40){
        this.firmaLength = true;
        return false;
      }
      else{
        if(this.company && this.parcel.modtager.modtagerfirma .length === 0){
          this.firmaLength = true;
          return false;
        }
        this.firmaLength = false;
      }


      if(this.parcel.package.serviceChoosen === 1){
        if(this.parcel.modtager.modtagernavn.length < 3 || this.parcel.modtager.modtagernavn.split(' ').length < 2) {
          this.fullnameError = true;
          return false;
        }
        this.fullnameError = false;
      }

      if(this.parcel.package.deposit === true && this.parcel.package.depositIntruction.length < 3){
        this.depositError = true;
        return false;
      }

      this.validateState("afsender", "afsenderphonenr");
      this.validateState("afsender", "afsendermail");
      this.validateState("modtager", "modtagernavn");
      if (!this.company) {
        this.validateState("afsender", "afsendernavn");
        this.validateState("modtager", "modtagerphonenr");
        this.validateState("modtager", "modtagermail");
      }
      if(this.selectedCountry !== "208")
      {
        this.validateState("modtager", "modtagerhusnr");
      }

      this.parcel.afsender.afsendervejnavn === ""
        ? (this.dawa1 = true)
        : (this.dawa1 = false);
      this.parcel.modtager.modtagervejnavn === ""
        ? (this.dawa2 = true)
        : (this.dawa2 = false);

      if (this.open) {
        this.$v.$touch();

        let errors = false;
        errors = await this.checkSender();

        if (errors) return;
        errors = await this.checkReciever();
        
        if (errors) return;
        errors = this.companyCheck;

        if (errors) return;

        if (this.parcel.package.serviceChoosen == 1) {
          if (
            this.parcel.package.parcelShopId &&
            this.parcel.package.parcelShopId.length > 0
          ) {
            errors = false;
          } else errors = true;
        }
        if (errors) return;

        if (!errors) {
          let cart = null;
          this.$emit("done", {
            id: this.parcel.id
          });
          if (this.parcel.id === 0) {
            try {
              this.parcel.package.deposit = typeof (this.parcel.package.deposit) == "string" ? false:this.parcel.package.deposit;
              const { data } = await LabelOnlineService.addToCart({
                cartId: this.$store.getters["parcels/getCartId"],
                ...this.parcel
              });
              cart = data;
              this.$store.commit("parcels/SET_COUNTER", 1);
              this.$store.commit("parcels/SET_EDITING", false);
              this.$store.commit("parcels/SET_CART_ITEMS", cart);
            }catch(err) {
              if (err.response.data){
                const errorObj = err.response.data.errors;

              if (
                (errorObj["package.height"] &&
                  errorObj["package.height"][0].includes("Could not convert string to integer")) ||
                (errorObj["package.width"] &&
                  errorObj["package.width"][0].includes("Could not convert string to integer")) ||
                (errorObj["package.length"] &&
                  errorObj["package.length"][0].includes("Could not convert string to integer")) ||
                (errorObj["package.weight"] &&
                  errorObj["package.weight"][0].includes("Could not convert string to integer"))) {
                this.sizeErrorDecimalNumberCheck = true;
              }
            }
            }
          } else {
            try {
              this.parcel.package.deposit = typeof (this.parcel.package.deposit) == "string" ? false:this.parcel.package.deposit;
              const { data } = await LabelOnlineService.updateCart({
                cartId: this.$store.getters["parcels/getCartId"],
                ...this.parcel
              });
              cart = data;
              this.$store.commit("parcels/SET_EDITING", false);
              this.$store.commit("parcels/SET_CART_ITEMS", cart);
              this.$emit("done", {
                id: this.parcel.id
              });
              const but = this.$refs.togglebut;
              but.classList.value = but.classList.value.replace(" expanded", "");

              this.$root.$emit(
                "bv::toggle::collapse",
                "collapse-" + this.parcel.id
              );
              this.open = false;




              this.location.reload();

              //scroolToTop
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }catch(err){
              const errorObj = err.response.data.errors;
              if(
                (errorObj["package.height"] &&
                errorObj["package.height"][0].includes("Could not convert string to integer")) ||
                (errorObj["package.width"] &&
                errorObj["package.width"][0].includes("Could not convert string to integer")) ||
                (errorObj["package.length"] &&
                errorObj["package.length"][0].includes("Could not convert string to integer"))||
                (errorObj["package.weight"] &&
                errorObj["package.weight"][0].includes("Could not convert string to integer"))){
                this.sizeErrorDecimalNumberCheck = true;
              }


            }
          }


        }
      }
    }
  }
};
</script>

<style lang="scss">
.delivery {
  margin-top: -7px;
}
.parcel-text{
  font-family: NewsonGLS-Bold;
  font-size: 24px;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.labelsName {
  margin-bottom: -10px;
  margin-top: 10px;
  font-size:15px;
}
button.line{
  background-color: #FFF;
  color:#000;
  border-bottom: 1px solid black;
}
.package-line{
  background-color:  #FFF !important;


}
button.line:focus{
  box-shadow: 0 0 0 transparent;
}
.line:hover{
  background-color: #C8E7F2;
}
.line-border{

}
.modal-footer .btn-secondary{
  background-color: #FFF;
  color:#061AB1;
  border-color:#061AB1;
}
.modal-footer .btn-secondary:hover{
  background-color: #061AB1;
  color:#FFF;
  border-color:#061AB1;
}
.modal-footer button.btn-primary{
  background-color: #ffd100;
  color:#000;
  border-color:#ffd100;
}
.modal-footer button.btn-primary:hover{
  background-color: #D6AF00;
}
.icons{
  margin-top:5px;
  img{
    margin-left:10px;
  }
}
</style>
