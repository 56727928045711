<template>
  <div class="mt-5 bg-primary">
    <div class="container body-content">
      <footer class="footer-collapse px-1 py-3 footer mt-3">
        <div class="col-lg-12 mx-auto">
          <div class="row mobilWeb">
            <div class="col-sm-6 col-md-4">
              <div class="widget d-none d-sm-block">
                <h4 class="text-bold">Direkte Links</h4>
                <ul class="widget-list list-unstyled p-0">
                  <li>
                    <a href="https://gls-group.eu/DK/da/om-gls" target="_blank"
                      >Om GLS</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/pakkeshop-betingelser"
                      target="_blank"
                      >Betingelser
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/data-og-sikkerhed"
                      target="_blank"
                      >Data og sikkerhed</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/privat/sikker-emballering"
                      target="_blank"
                      >Emballering</a
                    >
                  </li>
                  <li>
                    <a href="/fortryd">Fortryd dit køb</a>
                  </li>
                  <li>
                    <a href="https://gls-group.eu/DK/da/send" target="_blank"
                      >Kontakt GLS</a
                    >
                  </li>
                </ul>
              </div>

              <div
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
                class="widget d-sm-none"
              >
                <h4 class="text-bold">
                  <a v-b-toggle.collapse-1 variant="primary"
                    ><h5>Direkte links</h5></a
                  >
                </h4>
                <b-collapse id="collapse-1">
                  <ul class="list-unstyled">
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/om-gls"
                        target="_blank"
                        >Om GLS</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/pakkeshop-betingelser"
                        target="_blank"
                        >Betingelser
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/data-og-sikkerhed"
                        target="_blank"
                        >Data og sikkerhed</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/privat/sikker-emballering"
                        target="_blank"
                        >Emballering</a
                      >
                    </li>
                    <li>
                      <a href="/fortryd">Fortryd dit køb</a>
                    </li>

                    <li>
                      <a href="https://gls-group.eu/DK/da/send" target="_blank"
                        >Kontakt GLS</a
                      >
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <div class="widget d-none d-sm-block">
                <h4 class="text-bold">Søg</h4>
                <ul class="widget-list list-unstyled p-0">
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/gls-prisliste-fra-pakkeshop"
                      target="_blank"
                      >Priser</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/depoter-gls-pakke-shops.html"
                      target="_blank"
                      >Find nærmeste PakkeShop</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/leveringstid-fra-pakkeshop"
                      target="_blank"
                      >Leveringstider</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/find-pakke"
                      target="_blank"
                      >Spor din pakke</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://gls-group.eu/DK/da/faq-sendmedgls"
                      target="_blank"
                      >FAQ</a
                    >
                  </li>
                </ul>
              </div>
              <div
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
                class="widget d-sm-none"
              >
                <h4 class="text-bold">
                  <a v-b-toggle.collapse-2 variant="primary"><h5>Søg</h5></a>
                </h4>
                <b-collapse id="collapse-2">
                  <ul class="widget-list list-unstyled p-0">
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/gls-prisliste-fra-pakkeshop"
                        target="_blank"
                        >Priser</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/depoter-gls-pakke-shops.html"
                        target="_blank"
                        >Find nærmeste PakkeShop</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/leveringstid-fra-pakkeshop"
                        target="_blank"
                        >Leveringstider</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/find-pakke"
                        target="_blank"
                        >Spor din pakke</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://gls-group.eu/DK/da/faq-sendmedgls"
                        target="_blank"
                        >FAQ</a
                      >
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <div class="widget d-none d-sm-block">
                <h4 class="text-bold">GLS Denmark</h4>
                <div>
                  <p>
                    Kokmose 3, 6000 Kolding <br />CVR 10 54 97 44 <br />Telefon
                    nr. 76 33 11 11
                  </p>
                  <a
                    href="https://gls-group.eu/DK/da/corporate-information"
                    target="_blank"
                    >Klagemulighed</a
                  >
                  <div class="col-12 mt-2 text-center">
                    <a
                      href="https://www.facebook.com/GLSDenmark/"
                      target="_blank"
                      class="fa fa-facebook"
                    ></a
                    ><a
                      href="https://www.linkedin.com/company/gls-denmark"
                      target="_blank"
                      class="fa fa-linkedin"
                    ></a>
                  </div>
                </div>
              </div>

              <div
                role="tablist"
                aria-multiselectable="true"
                class="widget d-sm-none"
              >
                <h4 class="text-bold">
                  <a v-b-toggle.collapse-4 variant="primary"
                    ><h5>GLS Denmark</h5></a
                  >
                </h4>
                <b-collapse id="collapse-4">
                  <div>
                    <p>
                      Kokmose 3, 6000 Kolding <br />
                      CVR 10 54 97 44
                      <br />Telefon nr. 76 33 11 11 <br />
                    </p>

                    <a
                      href="https://gls-group.eu/DK/da/corporate-information"
                      target="_blank"
                      >Klagemulighed</a
                    >
                    <div class="col-12 mt-2 text-center">
                      <a
                        href="https://www.facebook.com/GLSDenmark/"
                        target="_blank"
                        class="fa fa-facebook"
                      ></a
                      ><a
                        href="https://www.linkedin.com/company/gls-denmark"
                        target="_blank"
                        class="fa fa-linkedin"
                      ></a>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <trust-pilot></trust-pilot>
        <div class="col-12 mt-2 mb-3 text-center">
          <img src="../assets/newdesign/klima.png" width="250" />
        </div>
      </footer>
      <div class="text-left betalingsInfo">
        <h6 style="font-size: 16px">Alle priser er inklusiv moms</h6>
        <h6 style="font-size: 16px">Betal med kreditkort eller MobilePay:</h6>
        <img
          src="../assets/mastercard.svg"
          width="40px"
          style="margin-bottom: 30px"
        /><img
          src="../assets/mobilpay.png"
          width="150px"
          style="margin-bottom: 30px"
        /><img
          src="../assets/visa.svg"
          width="40px"
          style="margin-bottom: 30px;background-color: #fff;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TrustPilot from './TrustPilot.vue';
export default {
  components: { TrustPilot },
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
h4,
h6 {
  color: #fff;
}
.betalingsInfo {
  text-align: left;
  margin-left: 15px;
  margin-top: -40px;
  @media screen and (max-width: 600px) {
    text-align: center !important;
    margin-left: 0px;
    margin-top: 0px;
  }
}

/* Style all font awesome icons */
.fa {
  padding: 7px;
  margin: 5px;
  font-size: 20px;
  width: 40px;
  text-align: center;
  text-decoration: none;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}
/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
  background: #3b5998;
  color: white;
}
p {
  font-size: 14px;
}

a {
  color: #454d76;
  font-size: 14px;
}
h5 {
  color: #fff;
}
a:hover {
  color: #c8e7f2 !important;
  text-decoration: underline;

  font-weight: bold;
  font-size: 14px;
}
.mobilWeb {
  text-align: left;
  @media screen and (max-width: 450px) {
    text-align: center;
  }
}
.footer-collapse p,
a {
  color: #fff;
}
</style>
