import { LabelOnlineService } from "../../services/LabelOnlineService";
import { DawaService } from "@/services/DawaService";
import { getSize } from "@/utils/sizeCalculator";

export default {
  namespaced: true,
  state: {
    cartId: "",
    cart: [],
    countries: [],
    parcelShopList: [],
    sizeList: {},
    zipcodes: [],
    customInvoice:null,
    editing:false,
    counter:0
  },
  mutations: {
    SET_COUNTER(state, payload){
      state.counter += payload;
    },
    SET_EDITING(state,payload){
      state.cart = state.cart.filter(x=> x.id != 0);
       state.editing = payload;
    },
    SET_ZIPCODES(state, payload) {
      state.zipcodes = payload;
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
    SET_CARTID(state, payload) {
      state.cartId = payload;
    },
    SET_CART_ITEMS(state, payload) {
      state.cart = payload;
    },
    SET_PARCEL(state, payload) {
      let parcelIndex = null;
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart[i].id == payload.id) {
          parcelIndex = i;
        }
      }
      let oldCart = [...state.cart];
      oldCart[parcelIndex] = payload;
      state.cart = [...oldCart];
    },
    SET_PARCEL_SIZE(state, payload) {
      var index = state.cart.findIndex(x => x.id === payload.id);
      var copyOfCart = [...state.cart];
      copyOfCart[index].package.height = payload.height;
      copyOfCart[index].package.length = payload.length;
      copyOfCart[index].package.width = payload.width;
      copyOfCart[index].package.weight = payload.weight;
      state.cart = [...copyOfCart];
    },
    SET_PARCEL_TYPE(state, payload) {
      var index = state.cart.findIndex(x => x.id === payload.id);
      var copyOfCart = [...state.cart];
      copyOfCart[index].package.serviceChoosen = payload.type;
      copyOfCart[index].package.packageType = payload.type;
      copyOfCart[index].package.price = payload.price;
      copyOfCart[index].package.productId = payload.productId;
      copyOfCart[index].package.description = payload.description;
      if (payload.type != 1) {
        copyOfCart[index].package.parcelShopId = "";
        copyOfCart[index].package.parcelShopName = "";
      }
      if (payload.type == 1) {
        copyOfCart[index].package.deposit = false;
        copyOfCart[index].package.depositIntruction = "";
      }

      state.cart = [...copyOfCart];
    },
    SET_PARCEL_PS(state, payload) {
      var index = state.cart.findIndex(x => x.id === payload.id);
      var copyOfCart = [...state.cart];
      copyOfCart[index].package.parcelShopId = payload.psId;
      copyOfCart[index].package.parcelShopName = payload.psName;
      state.cart = [...copyOfCart];
    },
    SET_PARCEL_DEPOSIT(state, payload) {
      var index = state.cart.findIndex(x => x.id === payload.id);
      var copyOfCart = [...state.cart];
      copyOfCart[index].package.deposit = payload.deposit;
      copyOfCart[index].package.depositIntruction = payload.depositIntruction;
      state.cart = [...copyOfCart];
    },
    SET_PARCEL_RESET_PRODUCT(state, payload) {
      var index = state.cart.findIndex(x => x.id === payload.id);
      var copyOfCart = [...state.cart];
      copyOfCart[index].package.deposit = "";
      copyOfCart[index].package.depositIntruction = "";
      copyOfCart[index].package.description = "";
      copyOfCart[index].package.parcelShopId = "";
      copyOfCart[index].package.parcelShopName = "";
      copyOfCart[index].package.productId = "";
      copyOfCart[index].package.serviceChoosen = 0;
      copyOfCart[index].package.packageType = 0;
    },
    ADD_NEW_PARCEL(state, payload) {
      state.cart.push(payload);
    },
    DELETE_PARCEL(state, payload) {
      let cart = [...state.cart];
      state.cart = cart.filter(x => x.id !== payload);
    },
    COPY_ADDRESS(state, payload) {
      var cart = [...state.cart];
      if (payload.type === "afsender") {
        cart[payload.index].afsender = payload.address;
      } else if (payload.type === "modtager") {
        cart[payload.index].modtager = payload.address;
      }
      state.cart = cart;
    },
    SET_INVOICE_ADDRESS(state,payload){
      var invoice = {...state.customInvoice};
      invoice = payload
      state.customInvoice = invoice;
    }
  },
  actions: {
    async updateInvoice(context, payload){
      context.commit('SET_INVOICE_ADDRESS',payload);
    },
    async copyAddress(context, payload) {
      context.commit("COPY_ADDRESS", payload);
    },
    async deleteParcel(context, payload) {
      if (payload.id === 0) {
        context.commit("DELETE_PARCEL", payload.id);
      } else {
        await LabelOnlineService.deleteFromCart({ id: payload.id });
        context.commit("DELETE_PARCEL", payload.id);
      }
    },
    async loadParcelShops() {},
    async updateParcel(context, payload) {
      context.commit("SET_PARCEL", payload);
    },
    async updateParcelSize(context, payload) {
      context.commit("SET_PARCEL_SIZE", { ...payload });
    },
    async updateParcelType(context, payload) {
      const parcel = context.state.cart.find(x => x.id == payload.id);
      const packageSize = getSize(parcel.package.weight);
      const { sizes } = payload.countries.find(
        x => x.numericCode == payload.countryId
      );
      
      const size = sizes.find(x => packageSize <= x.maxSize);      
      let price = 0;
      let description = "";
      let productId = "";
      console.log("payload",payload)
      if (payload.type == 1) {
        price = size.parcelShopPrice;
        description = size.parcelShopDescription;
        productId = size.parcelItemNo;
      } else if (payload.type == 2) {
        price = size.parcelAddressPrice;
        description = size.addressParcelDescription;
        productId = size.addressItemNo;
      } else if (payload.type == 3) {
        price = size.businessPrice;
        description = size.businessParcelDescription;
        productId = size.businessItemNo;
      }

      context.commit("SET_PARCEL_TYPE", {
        ...payload,
        price,
        productId,
        description,
        depost: payload.deposit ? true : false
      });
    },
    async updateParcelPS(context, payload) {
      context.commit("SET_PARCEL_PS", payload);
    },
    async updateParcelDeposit(context, payload) {
      context.commit("SET_PARCEL_DEPOSIT", payload);
    },
    updateParcelCompany(context) {
      context.commit("SET_PARCEL_COMPANY", {});
    },
    async loadDawaZipcodes(context) {
      // eslint-disable-next-line no-useless-catch
      try {
        context.commit("SET_LOADING", true, { root: true });
        const { data } = await DawaService.getAllZipcode();
        await context.commit("SET_ZIPCODES", data);
        context.commit("SET_LOADING", false, { root: true });
      } catch (err) {
        throw err;
      }
    },
    async getCart(context) {
      try {
        context.commit("SET_LOADING", true, { root: true });
        const cartId =
          localStorage.getItem("cartId") !== null &&
          localStorage.getItem("cartId") !== "undefined"
            ? localStorage.getItem("cartId")
            : null;

        const { data } = await LabelOnlineService.getCart(cartId);
        console.log(data)
        console.log(JSON.stringify(data))
        localStorage.setItem("cartId", data.guid);
        context.commit("SET_CARTID", data.guid);
        context.commit("SET_CART_ITEMS", data.cartItems);
        context.commit("SET_LOADING", false, { root: true });
      } catch (e) {
        console.log("fejl i getCart", e);
      }
    },
    async loadCountries(context) {
      // eslint-disable-next-line no-useless-catch
      try {
        context.commit("SET_LOADING", true, { root: true });
        const { data } = await LabelOnlineService.loadCountries();
        await context.commit("SET_COUNTRIES", data);
        context.commit("SET_LOADING", false, { root: true });
      } catch (err) {
        throw err;
      }
    },
    addProduct(context) {
      context.commit("ADD_NEW_PARCEL", {
        id: 0,
        afsender: {
          afsendernavn: "",
          afsendervejnavn: "",
          afsenderhusnr: "",
          afsenderpostnr: "",
          afsendercity: "",
          afsenderphonenr: "",
          afsendermail: "",
          afsendercountry: "208"
        },
        modtager: {
          modtagernavn: "",
          modtagervejnavn: "",
          modtagerhusnr: "",
          modtagerpostnr: "",
          modtagercity: "",
          modtagerphonenr: "",
          modtagermail: "",
          modtagercountry: "208",
          modtagerfirma: ""
        },
        package: {
          height: 0,
          width: 0,
          length: 0,
          weight: 0,
          serviceChoosen: "",
          parcelShopId: "",
          parcelShopName: "",
          price: 0,
          isBusinessParcel: false
        },
        confirmed: false,
        errorObj: {
          afsendernavn: false,
          afsendervejnavn: false,
          afsenderphonenr: false,
          afsenderhusnr: false,
          afsenderpostnr: false,
          afsendercity: false,
          afsendermail: false,
          modtagernavn: false,
          modtagerphonenr: false,
          modtagervejnavn: false,
          modtagerhusnr: false,
          modtagerpostnr: false,
          modtagercity: false,
          modtagermail: false,
          parcelExceeded: false
        }
      });
    }
  },
  getters: {
    getParcel: state => id => state.cart.filter(x => x.id === id)[0],
    getCartId: state => state.cartId,
    getCountries: state => state.countries
  }
};
