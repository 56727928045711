import Vue from "vue";
import Vuex from "vuex";
import parcels from "@/store/modules/parcels";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    parcels
  },
  state: {
    loading: false
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    }
  },
  actions: {}
});
