import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueGtag from "vue-gtag";
//css
import "./scss/app.scss";
import "../node_modules/animate.css/animate.min.css";

Vue.config.productionTip = false;


Vue.use(VueGtag, {  
  config: { id: "G-QY6VDY09M5" }
}, router);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
