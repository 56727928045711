<template>
  <div class="col-sm-12 col-md-6" v-if="!sizeCheck">
        <div
      class="text-center font-weight-bold GlsBlue"
      style="margin-bottom:30px;"
      v-if="parcel.modtager.modtagercountry == 208 && parcel.package.serviceChoosen !== 3"
    >
      4. Pakken skal leveres til
    </div>
    <div class="text-center font-weight-bold GlsBlue" v-if="parcel.modtagercountry === '208'">4. Vælg levering til</div>
    <div class="text-center font-weight-bold" v-else></div>
    <div class="row mt-3">
      <div
        class="col-12 d-md-flex"
        v-if="parcel.package.serviceChoosen !== 3 && parcel.modtager.modtagercountry == '208'"
      >
        <div class="col-md-5 col-sm-12">
          <b-button
            block
            size="lg"
            :variant="
              parcel.package.serviceChoosen === 1 ? `primary` : `white`
            "
            class="btn-outline-primary d-flex flex-column myBtn"
            :class="{'text-light':parcel.package.serviceChoosen === 1,'text-dark':parcel.package.serviceChoosen === 2}"
            @click="select(1)"
          >
            <small class="text-center col-12">GLS</small>
            <small class="text-center col-12">Pakkeshop</small>
            <small class="text-center col-12">{{ parcelShopPrice }} DKK</small>
          </b-button>
        </div>
        <div class="col-md-5 col-sm-12 mt-md-0 mt-sm-2">
          <b-button 
            block
            size="lg"
            :variant="
              parcel.package.serviceChoosen === 2 ? `primary` : `white`
            "
            class="btn-outline-primary d-flex flex-column"
            :class="{'text-light':parcel.package.serviceChoosen === 2,'text-dark':parcel.package.serviceChoosen === 1}"
            @click="select(2)"
          >
            <small class="text-center col-12">Privat </small>
            <small class="text-center col-12">adresse</small>
            <small class="text-center col-12"
              >{{ parcelAddressPrice }} DKK</small
            >
          </b-button>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="col-12 mt-4" v-if="parcel.package.serviceChoosen === 1">
          <div class="col-12">
            <label class="text-center col-12 mb-1"
              >Vælg en PakkeShop nær din modtager</label
            >
            <button
              class="btn btn-link col-12 p-0 m-0 text-blue"
              @click="searchPs = !searchPs"
            >
              {{
                !searchPs
                  ? "(eller søg ud fra en anden adresse)"
                  : "Fortryd søgning"
              }}
            </button>
          </div>
          <transition name="slide-fade">
            <template v-if="searchPs">
              <div class="col-12 mt-3">
                <div class="row">
                  <label class="col-12"
                    >Søg på enten postnr. eller adresse og postnr.</label
                  >
                  <div class="col-6 pr-1">
                    <b-form-input
                      size="sm"
                      placeholder="Adresse"
                      class="col-12"
                      v-model="psAddress"
                    ></b-form-input>
                  </div>
                  <div class="col-3 pr-1 pl-0">
                    <b-form-input
                      size="sm"
                      placeholder="Post nr."
                      class="col-12"
                      v-model="psZipcode"
                    ></b-form-input>
                  </div>
                  <div class="col-3 pl-0">
                    <b-button size="sm" variant="primary" class="col-12"
                              @click="customPSSearch"
                      >Søg</b-button
                    >
                  </div>
                </div>
              </div>
            </template>
          </transition>
          <div class="col-12 mt-1">
            <div class="row">
              <div class="col-12">
                <b-form-select
                  :class="{'animate__heartBeat animate__animated': animate}"
                  size="sm"
                  v-model="selectedShop"
                  :options="options"
                  @change="psSelected"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="col-12 mt-1">
            <p class="small text-center">Valgt PakkeShop: <strong>{{parcel.package.parcelShopName}}</strong></p>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div
          class="row col-12 mt-4 justify-content-center"
          v-if="parcel.package.serviceChoosen === 2 || (parcel.package.serviceChoosen === 3 && parcel.modtager.modtagercountry === '208')"
        >
          <div class="col-12 ">
            <label class="text-center col-12 mb-1"
              >Må vi stille pakken ?
              <span>
                <img
                  width="18"
                  src="../assets/Infoikon.png"
                  alt="info"
                  @click="$root.$emit('bv::show::modal', 'info-modal')"
                /> </span
            ></label>
            <b-modal
              id="info-modal"
              centered
              title="Må pakken stilles?"
              ok-only
            >
              <p class="my-4">
                Du giver tilladelse til, at GLS må levere pakken uden kvittering. Med DepositService kan GLS stille pakken i fx legehuset, carporten, receptionen eller lignende.
                Du accepterer også, at ansvaret for pakken overgår til dig, når GLS stiller pakken på det aftalte sted.
              </p>
            </b-modal>
          </div>
          <div class="text-center">
            <b-form-checkbox v-model="leave" @change="updateDeposit">
              Ja
            </b-form-checkbox>
          </div>
          <div class="col-12 mt-2 pl-4 pr-0 text-center" v-if="leave">
            <b-form-input
              placeholder="Fx. i carport"
              class="col-12"
              v-model.trim="intruction"
              @input="updateDeposit"
            ></b-form-input>
            <div class="invalid-feedback" style="display: block"  v-if="depositError && intruction.length < 3">
              Angiv venligst hvor pakken må stilles Feks. Carport. Mindst 3 tegn
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mt-4" v-if="parcel.package.serviceChoosen">
      <div class="ml-auto mr-4">

        <div class="text-right" v-if="parcel.package.serviceChoosen !== 3">
          Til: Adresse
        </div>
        <div class="text-right" v-else-if="parcel.package.serviceChoosen === 3 && parcel.modtagercountry === '208'">
          Til: Erhverv
        </div>

        <div v-else style="margin-bottom:20px;width:100%;height:55px;display:block;">&nbsp;</div>
        <div class="text-right" style="font-size:25px;">Pris: {{ calculatePrice }} DKK</div>
        <div>
          <b-button
            class="float-right action mt-4 rounded-pill"
            variant="secondary"
            @click="confirm"
            >Bekræft</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { LabelOnlineService } from "@/services/LabelOnlineService";
import { checkMaximumSize, getSize, checkMaxWeight } from "@/utils/sizeCalculator";
import { mapState } from "vuex";
export default {
  name: "PackageType",
  props: {
    selectedCountry: {
      type: Number,
      required: true
    },
    parcelId: {
      type: Number,
      required: true
    },
    company: {
      type: Boolean,
      required: true
    },
    depositError:{
      type:Boolean,
      required:true
    }
  },
  data() {
    return {
      animate:false,
      type: null,
      selectedShop: null,
      searchPs: false,
      country: null,
      leave: false,
      intruction: "",
      options: [],
      parcelShops: null,
      psZipcode:'',
      psAddress:''
    };
  },
  async created() {},
  mounted() {

    if(this.parcel.package.serviceChoosen > 1){
      this.leave = this.parcel.package.deposit;
      this.intruction = this.parcel.package.depositIntruction;
    }
    if (this.parcel.modtager.modtagercountry == "208" && !this.company) {
      this.loadPS();
    } else this.select(3);
  },
  watch: {
    price(newValue) {
      gsap.to(this.$data, { duration: 0.5, price: newValue });
    },
    company(){

      if(this.company) {
        this.select(3);
      }else{
        this.loadPS();

      }
    }
  },
  computed: {
    ...mapState("parcels", ["cart", "countries"]),
    parcel() {
      return this.cart.filter(x => x.id === this.parcelId)[0];
    },
    calculatePrice() {
      const sizes = this.countries.find(
        x => x.numericCode == this.parcel.modtager.modtagercountry
      ).sizes;
      var size = getSize(this.parcel.package.weight);
      
      var sizePrice = sizes.find(x => size <= x.maxSize);

      switch (this.parcel.package.serviceChoosen) {
        case 1:
          return sizePrice.parcelShopPrice;
        case 2:
          return sizePrice.parcelAddressPrice;
        case 3:
          return sizePrice.businessPrice;
        default:
          return 0;
      }
    },
    parcelShopPrice() {
      if (this.countries.length > 0) {
        let sizes = this.countries.find(
          x => x.numericCode == this.selectedCountry
        );

        sizes = sizes.sizes;
        const packackageSize = getSize(this.parcel.package.weight);
        
        const price = sizes.find(x => packackageSize <= x.maxSize)
          .parcelShopPrice;
        return price;
      }
      return 0;
    },
    parcelAddressPrice() {
      if (this.countries.length > 0) {
        const sizes = this.countries.find(
          x => x.numericCode == this.selectedCountry
        ).sizes;
        const packackageSize = getSize(this.parcel.package.weight);
        
        const price = sizes.find(x => packackageSize <= x.maxSize)
          .parcelAddressPrice;
        return price;
      }
      return 0;
    },
    parcelBusinessPrice() {
      const sizes = this.countries.find(
        x => x.numericCode == this.selectedCountry
      ).sizes;
      const packackageSize = getSize(this.parcel.package.weight);
      const price = sizes.find(x => packackageSize <= x.maxSize).businessPrice;
      return price;
    },
    sizeCheck() {
      let error = false;
      if (
        !checkMaximumSize(
          this.parcel.package.height,
          this.parcel.package.width,
          this.parcel.package.length
        ) && !checkMaxWeight(this.parcel.package.weight)
      ) {
        error = true;
      }
      return error;
    }
  },
  methods: {
    async customPSSearch(){
      const shops = await LabelOnlineService.getParcelShops(this.psAddress, this.psZipcode,"DK");
      const ps = [
        {
          text: "Vælg pakkeshop",
          value: null
        },
        ...shops.data.map(x => {
          return {
            text:
                `${x.companyName} - ${x.streetname}, ${x.zipCode} ${x.cityName}(` +
                this.financial(x.distanceMetersAsTheCrowFlies / 1000) +
                `) km`,
            value: x
          };
        })
      ];
      this.options = ps;
      this.selectedShop = null;
      var self = this;
      self.animate = true;
      setTimeout(function(){ self.animate = false; }, 1000);
    },
    financial(x) {
      return Number.parseFloat(x).toFixed(1);
    },
    select(type) {
      this.parcel.package.serviceChoosen = type;
      this.parcel.package.packageType = type;

      this.$store.dispatch("parcels/updateParcelType", {
        type,
        id: this.parcelId,
        countryId: this.parcel.modtager.modtagercountry,
        deposit: this.leave,
        countries: this.countries
      });
    },
    confirm() {
      this.$emit("confirm", true);
    },
    getISOCode() {
      if (this.countries.length > 0) {
        return this.countries.filter(
          x => x.numericCode === this.parcel.modtager.modtagercountry
        )[0].isoCode;
      }
      return null;
    },
    psSelected() {
      if(this.selectedShop) {
        this.$store.dispatch("parcels/updateParcelPS", {
          id: this.parcelId,
          psName: this.selectedShop.companyName,
          psId: this.selectedShop.number
        });
      }
    },
    updateDeposit() {
      this.$store.dispatch("parcels/updateParcelDeposit", {
        id: this.parcelId,
        deposit: this.leave,
        depositIntruction: this.leave ? this.intruction : ""
      });
    },
    loadPS() {
      const self = this;
      const parcelInfo = { ...this.parcel };

      LabelOnlineService.getParcelShops(
        parcelInfo.modtager.modtagervejnavn +
        " " +
        parcelInfo.modtager.modtagerhusnr,
        parcelInfo.modtager.modtagerpostnr,
        "DK"
      ).then(({ data }) => {
        this.parcelShops = data;
        const ps = [
          {
            text: "Vælg pakkeshop",
            value: null
          },
          ...data.map(x => {
            return {
              text:
                `${x.companyName} - ${x.streetname}, ${x.zipCode} ${x.cityName}(` +
                this.financial(x.distanceMetersAsTheCrowFlies / 1000) +
                `) km`,
              value: x
            };
          })
        ];
        self.options = ps;
        if (
          this.parcelShops &&
          this.parcel.package.parcelShopId &&
          this.parcel.package.parcelShopId.length > 0
        ) {
          this.selectedShop = this.parcelShops.find(
            x => x.number == this.parcel.package.parcelShopId
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.text-blue {
  color: rgb(98, 157, 189);
}
.btn-secondary:focus, .btn-secondary.focus{
  background-color: #fcbf00;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

.myBtn:active, .myBtn:hover .input.custom-control-input:focus{
background-color:  #fcbf00;
  border-color: #28a745 !important;
  box-shadow: #fcbf00;
}
</style>
