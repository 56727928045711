import axios from "axios";
import { labelApi } from "../config";
const apiClient = axios.create({
  baseURL: labelApi.url,
  withCredentials: false,
  headers: { Accept: "application/json", "Content-Type": "application/json" }
});
const LabelOnlineService = {
  async GetCartConfig(parcels) {
    return await apiClient.post(`/payment/paymentform`, parcels);
  },
  async loadCountries() {
    return await apiClient.get(`/countries`);
  },
  async getCart(cartId) {
    let query = "";
    if (cartId) {
      query = `?cartId=${cartId}`;
    }
    return await apiClient.get(`/cart/create${query}`);
  },
  async getParcelShops(street, zipcode, countryCode) {
    return await apiClient.get(
      `/parcelshops?street=${street}&zipcode=${zipcode}&countryCode=${countryCode}&amount=15`
    );
  },
  async checkZipcode(zipcode,countrycode){
    return await apiClient.get(`/countries/zipcode/${zipcode}/${countrycode}`)
  },
  async addToCart(parcel){
    return await apiClient.post('/cart', parcel);
  },
  async updateCart(parcel){
    return await apiClient.post('/cart/update', parcel);
  },
  async deleteFromCart(parcel){
    return await apiClient.post('/cart/delete', parcel);
  },
  async confirmReturn(order){
    return await apiClient.post('/return/confirmmail',order);
  },
  async checkCode(codeCheck){
    return await apiClient.post('/return/checkcode',codeCheck);
  },
  async cancelParcels(toCancel){
    return await apiClient.post('/return/orderlines',toCancel);
  },
  async accountRefund(accountInfo){
    return await apiClient.post('/return/account',accountInfo);
  },
  async sendBankInformation(data){
    return await apiClient.post('/return/account', data)
  },
  async UpdateInvoice(
    Name,
    Streetname,
    Housenumber,
    Zipcode,
    City,
    Phone,
    Mail,
    Country,
    CartId
  ) {
    const { data } = await apiClient.post("/cart/customer", {
      name: Name,
      streetname: Streetname,
      housenumber: Housenumber,
      zipcode: Zipcode,
      city: City,
      phone: Phone,
      mail: Mail,
      country: Country,
      cartid: CartId
    });
    return data;
  },
  async resetCart(cart) {
    return await apiClient.post('/cart/reset', cart);
  },
  async fetchPredefined(guid){
    return await apiClient.get(`/predefinedcompany?guid=${guid}`);
  }
};

export { LabelOnlineService };
